<template>
  <div class="row">
    <div class="col-1 text-start my-auto" v-if="filesToShow.length > 1">
      <button
        type="button"
        @click="$emit('back')"
        :disabled="index == 0"
        class="btn evidenceArrowBtn">
        <font-awesome-icon
          class="tableIcon pointer"
          :icon="['fa', 'chevron-left']" />
      </button>
    </div>
    <div class="col" v-if="filesToShow.length > 0">
      <transition name="scale" mode="out-in">
        <template v-if="fileType === 0">
          <div class="carousel_content_images">
            <img
              :src="filesToShow ? filesToShow[index].url : ''"
              alt="evidenceClaims"
              class="img-fluid" />
          </div>
        </template>
        <template v-else>
          <div class="carousel_content_images">
            <embed
              :src="filesToShow ? filesToShow[index].url : ''"
              width="800"
              height="500"
              type="application/pdf" />
          </div>
        </template>
      </transition>
    </div>
    <div
      class="col-1 text-end align-middle my-auto"
      v-if="filesToShow.length > 1">
      <button
        type="button"
        @click="$emit('next')"
        :disabled="index >= filesToShow.length - 1"
        class="btn evidenceArrowBtn">
        <font-awesome-icon
          class="tableIcon pointer"
          :icon="['fa', 'chevron-right']" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselContent",
  props: {
    // fileType: 0 => image, 1 => pdf
    fileType: {
      type: Number,
      required: true,
    },
    filesToShow: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.carousel_content_images {
  max-height: 650px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel_content_images img {
  max-height: 650px;
  max-width: 100%;
}
</style>
